import { API_SERVER } from '../constants';

export default {
  post: async function (path, data, header) {
    const qs = new URLSearchParams(location.search);
    const selfToken = localStorage.getItem('custom_token');
    const Header = {
      method: "post",
      headers: {
        "content-type": "application/json",
        authorization: `bearer ${selfToken}`,
        ...(header || {}),
      },
      body: JSON.stringify(data ? data : {})
    }
    if (qs.get('domain')) Header.domain = qs.get('domain');
    const result = await fetch(`${API_SERVER}${path}`, Header).then(r => r.json());
    if (result.code === 200) return result.data;
    throw result.msg;
  },
  get: async function (path, header) {
    const qs = new URLSearchParams(location.search);
    const selfToken = localStorage.getItem('custom_token');
    try {
      const Header = {
        method: "get",
        headers: {
          authorization: `bearer ${selfToken}`,
          ...(header || {}),
        },
      }
      if (qs.get('domain')) Header.domain = qs.get('domain');
      const result = await fetch(`${API_SERVER}${path}`, Header).then(r => r.json());
      if (result.code === 200) return result.data;
      throw result.msg;
    } catch (e) {
      throw e;
    }
  }
}